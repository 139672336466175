/*! TACHYONS v4.12.0 | http://tachyons.io */
.react-flow {
  direction: ltr;
}

.react-flow__container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.react-flow__pane {
  z-index: 1;
  cursor: -webkit-grab;
  cursor: grab;
}

.react-flow__pane.selection {
  cursor: pointer;
}

.react-flow__pane.dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.react-flow__viewport {
  transform-origin: 0 0;
  z-index: 2;
  pointer-events: none;
}

.react-flow__renderer {
  z-index: 4;
}

.react-flow__selection {
  z-index: 6;
}

.react-flow__nodesselection-rect:focus, .react-flow__nodesselection-rect:focus-visible {
  outline: none;
}

.react-flow .react-flow__edges {
  pointer-events: none;
  overflow: visible;
}

.react-flow__edge-path, .react-flow__connection-path {
  stroke: #b1b1b7;
  stroke-width: 1px;
  fill: none;
}

.react-flow__edge {
  pointer-events: visibleStroke;
  cursor: pointer;
}

.react-flow__edge.animated path {
  stroke-dasharray: 5;
  animation: .5s linear infinite dashdraw;
}

.react-flow__edge.animated path.react-flow__edge-interaction {
  stroke-dasharray: none;
  animation: none;
}

.react-flow__edge.inactive {
  pointer-events: none;
}

.react-flow__edge.selected, .react-flow__edge:focus, .react-flow__edge:focus-visible {
  outline: none;
}

.react-flow__edge.selected .react-flow__edge-path, .react-flow__edge:focus .react-flow__edge-path, .react-flow__edge:focus-visible .react-flow__edge-path {
  stroke: #555;
}

.react-flow__edge-textwrapper {
  pointer-events: all;
}

.react-flow__edge-textbg {
  fill: #fff;
}

.react-flow__edge .react-flow__edge-text {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.react-flow__connection {
  pointer-events: none;
}

.react-flow__connection .animated {
  stroke-dasharray: 5;
  animation: .5s linear infinite dashdraw;
}

.react-flow__connectionline {
  z-index: 1001;
}

.react-flow__nodes {
  pointer-events: none;
  transform-origin: 0 0;
}

.react-flow__node {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: all;
  transform-origin: 0 0;
  box-sizing: border-box;
  cursor: -webkit-grab;
  cursor: grab;
  position: absolute;
}

.react-flow__node.dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.react-flow__nodesselection {
  z-index: 3;
  transform-origin: 0 0;
  pointer-events: none;
}

.react-flow__nodesselection-rect {
  pointer-events: all;
  cursor: -webkit-grab;
  cursor: grab;
  position: absolute;
}

.react-flow__handle {
  pointer-events: none;
  background: #1a192b;
  border: 1px solid #fff;
  border-radius: 100%;
  width: 6px;
  min-width: 5px;
  height: 6px;
  min-height: 5px;
  position: absolute;
}

.react-flow__handle.connectionindicator {
  pointer-events: all;
  cursor: crosshair;
}

.react-flow__handle-bottom {
  top: auto;
  bottom: -4px;
  left: 50%;
  transform: translate(-50%);
}

.react-flow__handle-top {
  top: -4px;
  left: 50%;
  transform: translate(-50%);
}

.react-flow__handle-left {
  top: 50%;
  left: -4px;
  transform: translate(0, -50%);
}

.react-flow__handle-right {
  top: 50%;
  right: -4px;
  transform: translate(0, -50%);
}

.react-flow__edgeupdater {
  cursor: move;
  pointer-events: all;
}

.react-flow__panel {
  z-index: 5;
  margin: 15px;
  position: absolute;
}

.react-flow__panel.top {
  top: 0;
}

.react-flow__panel.bottom {
  bottom: 0;
}

.react-flow__panel.left {
  left: 0;
}

.react-flow__panel.right {
  right: 0;
}

.react-flow__panel.center {
  left: 50%;
  transform: translateX(-50%);
}

.react-flow__attribution {
  background: #ffffff80;
  margin: 0;
  padding: 2px 3px;
  font-size: 10px;
}

.react-flow__attribution a {
  color: #999;
  text-decoration: none;
}

@keyframes dashdraw {
  from {
    stroke-dashoffset: 10px;
  }
}

.react-flow__edgelabel-renderer {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.react-flow__edge.updating .react-flow__edge-path {
  stroke: #777;
}

.react-flow__edge-text {
  font-size: 10px;
}

.react-flow__node.selectable:focus, .react-flow__node.selectable:focus-visible {
  outline: none;
}

.react-flow__node-default, .react-flow__node-input, .react-flow__node-output, .react-flow__node-group {
  color: #222;
  text-align: center;
  background-color: #fff;
  border: 1px solid #1a192b;
  border-radius: 3px;
  width: 150px;
  padding: 10px;
  font-size: 12px;
}

.react-flow__node-default.selectable:hover, .react-flow__node-input.selectable:hover, .react-flow__node-output.selectable:hover, .react-flow__node-group.selectable:hover {
  box-shadow: 0 1px 4px 1px #00000014;
}

.react-flow__node-default.selectable.selected, .react-flow__node-default.selectable:focus, .react-flow__node-input.selectable.selected, .react-flow__node-input.selectable:focus, .react-flow__node-output.selectable.selected, .react-flow__node-output.selectable:focus, .react-flow__node-group.selectable.selected, .react-flow__node-group.selectable:focus, .react-flow__node-default.selectable:focus-visible, .react-flow__node-input.selectable:focus-visible, .react-flow__node-output.selectable:focus-visible, .react-flow__node-group.selectable:focus-visible {
  box-shadow: 0 0 0 .5px #1a192b;
}

.react-flow__node-group {
  background-color: #f0f0f040;
}

.react-flow__nodesselection-rect, .react-flow__selection {
  background: #0059dc14;
  border: 1px dotted #0059dccc;
}

.react-flow__nodesselection-rect:focus, .react-flow__selection:focus, .react-flow__nodesselection-rect:focus-visible, .react-flow__selection:focus-visible {
  outline: none;
}

.react-flow__controls {
  box-shadow: 0 0 2px 1px #00000014;
}

.react-flow__controls-button {
  box-sizing: content-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #fefefe;
  border: none;
  border-bottom: 1px solid #eee;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 5px;
  display: flex;
}

.react-flow__controls-button:hover {
  background: #f4f4f4;
}

.react-flow__controls-button svg {
  width: 100%;
  max-width: 12px;
  max-height: 12px;
}

.react-flow__controls-button:disabled {
  pointer-events: none;
}

.react-flow__controls-button:disabled svg {
  fill-opacity: .4;
}

.react-flow__minimap {
  background-color: #fff;
}

.react-flow__minimap svg {
  display: block;
}

.react-flow__resize-control {
  position: absolute;
}

.react-flow__resize-control.left, .react-flow__resize-control.right {
  cursor: ew-resize;
}

.react-flow__resize-control.top, .react-flow__resize-control.bottom {
  cursor: ns-resize;
}

.react-flow__resize-control.top.left, .react-flow__resize-control.bottom.right {
  cursor: nwse-resize;
}

.react-flow__resize-control.bottom.left, .react-flow__resize-control.top.right {
  cursor: nesw-resize;
}

.react-flow__resize-control.handle {
  background-color: #3367d9;
  border: 1px solid #fff;
  border-radius: 1px;
  width: 4px;
  height: 4px;
  transform: translate(-50%, -50%);
}

.react-flow__resize-control.handle.left {
  top: 50%;
  left: 0;
}

.react-flow__resize-control.handle.right {
  top: 50%;
  left: 100%;
}

.react-flow__resize-control.handle.top {
  top: 0;
  left: 50%;
}

.react-flow__resize-control.handle.bottom {
  top: 100%;
  left: 50%;
}

.react-flow__resize-control.handle.top.left, .react-flow__resize-control.handle.bottom.left {
  left: 0;
}

.react-flow__resize-control.handle.top.right, .react-flow__resize-control.handle.bottom.right {
  left: 100%;
}

.react-flow__resize-control.line {
  border: 0 solid #3367d9;
}

.react-flow__resize-control.line.left, .react-flow__resize-control.line.right {
  width: 1px;
  height: 100%;
  top: 0;
  transform: translate(-50%);
}

.react-flow__resize-control.line.left {
  border-left-width: 1px;
  left: 0;
}

.react-flow__resize-control.line.right {
  border-right-width: 1px;
  left: 100%;
}

.react-flow__resize-control.line.top, .react-flow__resize-control.line.bottom {
  width: 100%;
  height: 1px;
  left: 0;
  transform: translate(0, -50%);
}

.react-flow__resize-control.line.top {
  border-top-width: 1px;
  top: 0;
}

.react-flow__resize-control.line.bottom {
  border-bottom-width: 1px;
  top: 100%;
}

html {
  -webkit-text-size-adjust: 100%;
  min-height: min-content;
  line-height: 1.15;
}

body {
  background-color: #37393e;
  min-height: min-content;
  margin: 0;
  overflow-x: hidden;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  text-decoration: underline;
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html, body, div, article, aside, section, main, nav, footer, header, form, fieldset, legend, pre, code, a, h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd, blockquote, figcaption, figure, textarea, table, td, th, tr, .border-box {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

input:-webkit-autofill {
  transition: background-color 0s 600000s, color 0s 600000s;
}

input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.modal-root {
  overflow: scroll;
}
/*# sourceMappingURL=index.ea3daed6.css.map */
